<template>
  <!-- 开票申请审批 -->
  <div>
     <div class="title"> <i class="el-icon-arrow-left icon" @click="$router.go(-1)"></i>提交详情页-开票申请</div>
    <el-card style="margin-top: 10px;">
        <p style="text-align:center">{{name}}{{no}}</p>
    <el-steps :active="active" finish-status="success"  :process-status="dt" align-center class="xunH">
      <el-step :title="item.operationName"  v-for="item in flows" :key="item.id" >
        <template slot="description">
          <p style="margin-top:5px">{{ item.dealEmpName }}</p>
          <p>{{ item.dealTime }}</p>
        </template>
      </el-step>
    </el-steps>
    <div  class="left1" style="margin-top:20px;font-size:14px">
      <div style="display:inline-block">发票编号 <el-input v-model="log.fhdm" style="width:400px" disabled></el-input></div>
      <div style="display:inline-block;margin-left:50px">快递编号 <el-input v-model="log.expressNo" style="width:400px" disabled></el-input></div>
    </div>
    <div class="ben">
        <p class="text1 left1">本次申请发票</p>
        <div class="heng"></div>
        <el-table
        :header-cell-style="{background:'#9e99b9',color:'#ffff'}"
        :data="dataList"
        style="width: 80%;margin:30px auto">
        <el-table-column
            prop="taskName"
            header-align="center"
            align="center"
            label="任务名称"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            prop="batchNo"
            header-align="center"
            align="center"
            label="订单号(批次号)"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            prop="amount"
            header-align="center"
            align="center"
            label="金额"
            show-overflow-tooltip
        ></el-table-column>
        </el-table>
        <el-pagination
        background
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="currPage"
        :page-sizes="[3, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalCount"
        class="text_center"
        layout="total, sizes, prev, pager, next, jumper"
         style="text-align:right;margin-top:30px;margin-right:160px"
        ></el-pagination>
    </div>
    <div class="ben">
      <p class="text1 left1">发票抬头</p>
      <div class="heng"></div>
      <el-form label-width="140px" class="left1" style="margin-top:20px">
        <el-form-item label="企业名称">
            {{log.merchantName}}
        </el-form-item>
        <el-form-item label="统一社会信用代码">
            {{log.companyTaxNum}}
        </el-form-item>
        <el-form-item label="对公账户开户行">
            {{info.bankName}}
        </el-form-item>
        <el-form-item label="对公账户号">
            {{info.bankNo}}
        </el-form-item>
        <el-form-item label="公司地址">
            {{info.companyAddress}}
        </el-form-item>
      </el-form>
    </div>
    <div class="ben">
      <p class="text1 left1">收件地址</p>
      <div class="heng"></div>
      <div class="box left1">
          <p style="margin-top:15px;margin-left:15px;font-size:14px">收件信息</p>
          <div class="heng"></div>
          <el-form label-width="140px" style="margin-bottom:20px">
            <el-form-item label="收件人">
                {{address.userName}}
            </el-form-item>
            <el-form-item label="联系电话">
                {{address.userMobile}}
            </el-form-item>
            <el-form-item label="邮编">
                {{address.postCode}}
            </el-form-item>
            <el-form-item label="收件地址">
                {{address.userAddress}}
            </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="ben">
      <p class="text1 left1">邮寄方式</p>
      <div class="heng"></div>
       <div style="margin-top:40px"  class="left1">
              <el-radio v-model="radio" v-if="radio==502010"  label="502010">月寄</el-radio>
              <el-radio v-model="radio" v-else   label="502020">邮寄到付</el-radio>
              <div v-if="radio==502010" style="margin-top:14px">月寄是截止到每月最后一天统一邮寄，快递默认顺丰，无需承担费用。</div>
              <div v-else style="margin-top:14px">邮寄到付是每结算成功一批次，寄一次发票.工作人员收到发票申请后，会在一个工作日内安排寄出，邮寄到付产生的快递费需要自行承担。</div>
           </div>
    </div>
    <div class="ben">
      <p class="text1 left1">备注</p>
      <div class="heng"></div>
      <div class="bob left1">{{log.remark}}</div>
    </div>
    </el-card>
    
  </div>
</template>

<script>
import { taskDetail , invoiceListByLogId} from  "@/api/workbench.js";
export default {
//   inject:['reload'],
  data() {
    return {
     active: 0,
     dt: "",
     dataList: [],
      // 分页
      pageSize: 3,
      totalPage: 0,
      totalCount: 0,
      currPage: 1,
      radio:'',
      info:"",//发票抬头
      log:"",
      address:"",
      flows:[],
      name:"",
      no:""

    };
  },
  mounted(){
    this.getDataList()
  },
  methods: {
    //获取数据
      getDataList(){
        let params = new URLSearchParams();
        params.append("taskId", this.$route.query.id);
        taskDetail(params).then((data) => {
          if(data.code == 200) {
                this.$forceUpdate()
                this.info=data.data.busDetail.info //抬头
                this.log=data.data.busDetail.log  
                this.address=data.data.busDetail.address //地址
                this.flows=data.data.flows //进度
                this.radio = data.data.busDetail.log.sendType.toString() //邮寄方式
                 this.name =data.data.name //审批流名称
                 this.no = data.data.no //流程编号
                 this.flows.push({operationName:'已完成',operation:data.data.state})
                this.flows.map((item,index) => {
                    if(item.operation == 2 ) {
                        this.dt = 'error'
                    }
                    if (item.operation == 0 || item.operation == 1 ) {
                        this.active=index+1
                    }
                })
                console.log(this.log,"pp");
           this.invoiceListByLogIdg()
          }
        })
      },
      invoiceListByLogIdg(){
        let params = {
          id:this.log.id,
          current:this.currPage,
          size:this.pageSize
        }
        invoiceListByLogId(params).then((data) => {
          if(data && data.code == 200) {
            this.dataList = data.data.records
            this.totalCount = data.data.total
          }else {
            this.dataList = []
            this.totalCount = ''
          }
        })
      },
    
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.currPage = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.currPage = val;
      this.getDataList();
    },
  },
};
</script>

<style scoped lang="scss">
.xunH {
  margin-top: 50px;
}
.heng {
  height: 1px;
  margin-top: 10px;
  background-color: rgb(223, 213, 213);
  overflow: hidden;
}
.ben {
  margin-top: 50px;
}
.text {
  margin-top: 20px;
  font-size: 14px;
}
.text1 {
  color: black;
  font-weight: 600;
  font-size: 14px;
}
div /deep/ .el-form-item {
    margin-bottom: 0;
}
.box {
    margin-top:30px;
    width: 500px;
    border: 1px solid #999;
}
.bob {
    margin-top:20px;
     width:80%;
    height: 200px;
    border: 1px solid rgb(179, 176, 176);
    // background-color: #f0f0f0;
}
.demo-ruleForm {
    margin-top: 50px;
}

.last1 {
  width: 100%;
  height: 100px;
  border: 0;
  outline: none;
}
/deep/.el-step__head.is-wait :nth-child(4){
  color: red !important;
}
.title{
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    background: #ffff;
    margin-top:-20px;
    margin-left:-20px;
    width: 105%;
    text-indent: 20px;
}
.icon{
    font-size: 30px;
    transform: translateY(5px);
     color: #b5b5b5;
    margin-right: 5px;
    cursor: pointer;
}
.left1{
  margin-left:160px
}
</style>